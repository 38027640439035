import { useState } from "react";
import HalfWavesImg from "../../assets/02/WaveLines.png";
import TermsImageSrc from "../../assets/05/Terms.png";
import { FinishImage, Footer } from "../_Global/Global";
import { CenterDiv, HalfWavesImage } from "../_Global/globalComponents";
import * as sc from "./styledComponents";

const DataCollection = () => {
  return <div>
    <sc.SectionTitle>Data Collection and Usage</sc.SectionTitle>
    <sc.SectionList>
      <sc.SectionListItem>
        We only collect information that's necessary to provide our gaming services.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We store your account credentials to maintain your gaming profile and progress.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We track game performance data to improve your gaming experience.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We use cookies to keep you logged in and remember your preferences.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We monitor technical data to ensure our services run smoothly.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We analyze gameplay patterns to detect and prevent cheating.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We maintain server logs to protect against security threats.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We process payment information when you make purchases.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We keep chat records to maintain a safe gaming environment.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We collect feedback data when you report issues or contact support.
      </sc.SectionListItem>
    </sc.SectionList>
  </div>
}

const UserRights = () => {
  return <div>
    <sc.SectionTitle>Your Rights and Our Responsibilities</sc.SectionTitle>
    <sc.SectionList>
      <sc.SectionListItem>
        You can request a copy of all your personal data at any time.
      </sc.SectionListItem>
      <sc.SectionListItem>
        You can delete your account and associated data permanently.
      </sc.SectionListItem>
      <sc.SectionListItem>
        You can opt out of non-essential data collection.
      </sc.SectionListItem>
      <sc.SectionListItem>
        You can change or update your personal information anytime.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We will notify you about significant changes to this privacy policy.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We will never sell your personal information to third parties.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We will respond to your privacy questions within 48 hours.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We will protect your data using industry-standard security measures.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We will only share your data when required by law.
      </sc.SectionListItem>
      <sc.SectionListItem>
        We will delete your data if you're inactive for more than 24 months.
      </sc.SectionListItem>
    </sc.SectionList>
  </div>
}

const Privacy = () => {
  const [animationStatus, setAnimationStatus] = useState("Play");
  window.onpopstate = function (event) {
    setAnimationStatus("PlayReverse");
    setTimeout(() => {
      setAnimationStatus("Stop");
    }, 1000);
  };
  return <sc.Wrapper status={animationStatus}>
    <HalfWavesImage src={HalfWavesImg} status={animationStatus} />
    <sc.TermsWrapper>
      <sc.PaddingWrapper>
        <CenterDiv><sc.TermsImage src={TermsImageSrc} /></CenterDiv>
        <sc.TermsTitle>Privacy Policy</sc.TermsTitle>
        <sc.TermsText>
          Your privacy is critically important to us. Below is our privacy policy which incorporates our fundamental principles:
        </sc.TermsText>
        <DataCollection />
        <UserRights />
      </sc.PaddingWrapper>
      <FinishImage />
      <Footer />
    </sc.TermsWrapper>
  </sc.Wrapper>
}

export default Privacy;