import { Route } from "react-router-dom";
import { About, Games, Home, PageNotFound, Purpose, Terrain } from "../components";
import Battle from "../components/Battle";
import Privacy from "../components/Privacy";
import Terms from "../components/Terms";

import {
  ABOUT_PATH,
  BATTLE_PATH,
  GAMES_PATH,
  HOME_PAGE_PATH,
  PRIVACY_PATH,
  PURPOSE_PATH,
  TERMS_PATH,
  TERRAIN_PATH
} from "../constants/routeConstants";

const routes = [
  <Route key={HOME_PAGE_PATH} path={HOME_PAGE_PATH} element={<Home />} />,
  <Route key={GAMES_PATH} path={GAMES_PATH} element={<Games />} />,
  <Route key={ABOUT_PATH} path={ABOUT_PATH} element={<About />} />,
  <Route key={TERRAIN_PATH} path={TERRAIN_PATH} element={<Terrain />} />,
  <Route key={PURPOSE_PATH} path={PURPOSE_PATH} element={<Purpose />} />,
  <Route key={TERMS_PATH} path={TERMS_PATH} element={<Terms />} />,
  <Route key={PRIVACY_PATH} path={PRIVACY_PATH} element={<Privacy />} />,
  <Route key={BATTLE_PATH} path={BATTLE_PATH} element={<Battle />} />,
  <Route key="NOT_FOUND" path="*" element={<PageNotFound/>} />
];

export { routes };
